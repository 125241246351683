import { useDispatch } from 'react-redux';

import { auth as types } from '@codesass/types';

import * as actions from '../slice';

import AuthForm from './Form';

const SendResetPassword = () => {
  const dispatch = useDispatch();

  const resetPassword = ({ email }: types.UserCredential) => {
    dispatch(actions.sendResetPassword({ email }));
  };

  return (
    <AuthForm
      variant="send-reset-password"
      title="รีเซ็ตรหัสผ่าน"
      altTitle="ยังไม่มีบัญชีผู้ใช้งาน?"
      altText="ลงทะเบียน"
      altLink="/auth/register"
      onSubmit={resetPassword}
    ></AuthForm>
  );
};

export default SendResetPassword;
