import SendResetPassword from 'modules/auth/components/SendResetPassword';
import PreventLoggedInGuard from 'modules/auth/components/PreventLoggedInGuard';

const SendResetPasswordPage = () => {
  return (
    <PreventLoggedInGuard>
      <SendResetPassword></SendResetPassword>
    </PreventLoggedInGuard>
  );
};

export default SendResetPasswordPage;
